import React from "react"
import Fade from "react-awesome-reveal"

const PricingHeader = () => {
  return (
    <div className="max-w-7xl mx-auto mt-10 font-poppins text-white flex flex-col items-center">
      <Fade bottom>
        <h1 className="text-6xl font-bold m-5 xxs:text-xl sm:text-4xl lg:text-6xl">
          Simple, transparent pricing
        </h1>
        <h2 className="text-base opacity-50 text-center xxs:text-sm sm:text-lg">
          No up front costs. Management, hosting and payment fees covered by game entry.
        </h2>
      </Fade>
    </div>
  )
}

export default PricingHeader
