import React from "react"
import Button from "../Atoms/button"
import Fade from "react-awesome-reveal"

const PricingMain = () => {
  return (
    <div className="max-w-7xl mx-auto px-6 h-screen xxs:hidden xs:hidden sm:hidden lg:block">
      <Fade bottom>
        <div className="w-full bg-gradient-to-r from-pink to-purple flex items-center justify-center h-screen rounded-xl">
          <div className="flex w-9/12 bg-white h-auto rounded-xl">
            <div className="p-8 w-3/5">
              <h1 className="text-3xl font-bold">
                Unlimited access to all<br></br>features.
              </h1>
              <p className="mt-5 text-sm opacity-50 font-semibold">
                Create a traditional domino card game, set your own entry fee, set your own prize, and invite your community to play.
              </p>
              <div className="mt-5">
                <h2 className="font-bold text-xl">What's Included</h2>
                <div className="mt-5 grid grid-cols-2 gap-3">
                  <h3>Automate ongoing games</h3>
                  <h3>Payment processing fees</h3>
                  <h3>Automatic notification of winner to all entrants</h3>
                  <h3>Customisable game fees and prizes</h3>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-2/5 p-8 bg-pink rounded-r-lg">
              <h1 className="text-7xl text-white font-bold mt-2 text-center">Free to set up</h1><br></br>
              <h2 className="text-white text-md font-bold mt-5">20% ongoing fee</h2>
              <div className="mt-5">
                <Button
                  colorClass="bg-white"
                  title="Get Started"
                  onClick={() => {}}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default PricingMain
